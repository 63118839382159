<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Edit shipping address</span>
            </div>

            <div v-loading="loading" class="ns-member-address-list">
                <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="80px">

                    <!-- 姓名 -->
                    <el-form-item label="Name" prop="name">
                        <el-input v-model="formData.name" placeholder="please enter full first and last name"
                            class="ns-len-input"></el-input>
                    </el-form-item>

                    <!-- 邮箱地址 -->
                    <el-form-item label="Email" prop="email" v-if="!Token">
                        <el-input v-model="formData.email" placeholder="please input your email"
                            class="ns-len-input"></el-input>
                        <p style="color: #B59080;line-height: 2;">Your email will be used to register an account, please
                            fill in carefully.</p>
                        <p style="line-height: 1;color: #B59080;">Please use Gmail, outlook, ProtonMail, yahoo and other
                            mainstream mailboxes, other mailboxes may not receive emails.</p>
                    </el-form-item>

                    <!-- 区号手机号 -->
                    <el-form-item label="Telephone" prop="mobile">
                        <el-input placeholder="consignee phone number" v-model="formData.mobile" style="width: 400px;">
                            <el-select style="width: 130px;margin-right: -20px;border-right: none;"
                                v-model="formData.areacode" slot="prepend" placeholder="areacode">
                                <el-option v-for="(v, i) in nationList" :key="i" :label="`${v.name} (+${v.phonecode})`"
                                    :value="v.id"></el-option>
                            </el-select>
                        </el-input>
                        <p style="color: #B59080;line-height: 2;">If your telephone number incorrect，you may not receive
                            your package in time.</p>
                    </el-form-item>

                    <!-- <el-form-item label="电话">
                        <el-input v-model.trim="formData.telephone" autocomplete="off" placeholder="收货人固定电话（选填）"
                            class="ns-len-input"></el-input>
                    </el-form-item> -->

                    <!-- 国家 州/省 区/县 -->
                    <el-form-item label="Address1" prop="country_id">
                        <!-- 国家 -->
                        <el-select v-model="formData.country_id" placeholder="please select country"
                            @change="changeProvice(formData.country_id)">
                            <el-option v-for="option in nationList" :key="option.id" :label="option.name"
                                :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <!-- 州/省 -->
                        <el-select v-model="formData.stateCounty_id" placeholder="Please select state/county">
                            <el-option v-for="option in stateCountyList" :key="option.id" :label="option.name"
                                :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <!-- 区/县 -->
                        <!-- <el-select v-model="formData.district_id" placeholder="请选择区/县"
                            @change="changeDistrict(formData.district_id)">
                            <el-option v-for="option in district" :key="option.id" :label="option.name"
                                :value="option.id">{{ option.name }}</el-option>
                        </el-select> -->
                        <p style="color: #B59080;line-height: 2;">If there is no state/province you need, please select
                            Other, and then fill in your state/province
                            in city</p>
                    </el-form-item>
                    <!-- 城市 -->
                    <el-form-item label="City" prop="city_name">
                        <el-input v-model.trim="formData.city_name" autocomplete="off"
                            placeholder="Please fill in the city name" class="ns-len-input"></el-input>
                    </el-form-item>

                    <!-- 详细地址 -->
                    <el-form-item label="Address2" prop="address">
                        <el-input v-model.trim="formData.address" autocomplete="off"
                            placeholder="Community, street, office building" class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Zip Code" prop="zip_code">
                        <el-input v-model.trim="formData.zip_code" autocomplete="off"
                            placeholder="Please fill in the zip code" class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Default address">
                        <el-radio-group v-model="formData.is_default">
                            <el-radio :label="1">Yes</el-radio>
                            <el-radio :label="0">No</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="saveAddress('ruleForm')">Save</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
import { addressInfo, saveAddress } from "@/api/member/member"
import { getArea, getCountries, getAreaByPid } from "@/api/address"
import { getToken } from "../../utils/auth.js"

export default {
    name: "address_edit",
    components: {},
    data() {
        let self = this
        var isMobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("手机号不能为空"))
            } else {
                const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                if (reg.test(value)) {
                    callback()
                } else {
                    callback(new Error("请输入正确的手机号"))
                }
            }
        }

        var fullAddress = (rule, value, callback) => {
            if (self.formData.country_id) {
                if (self.formData.stateCounty_id) {
                    return callback()
                } else {
                    return callback(new Error("Please select State/County"))
                }
            } else {
                return callback(new Error("please select country"))
            }
        }

        return {
            formData: {
                id: 0,
                name: "", // 姓名
                email: "", // 电子邮箱
                mobile: "", // 手机号码
                areacode: "", // 手机区号
                country_id: "", // 国家id
                stateCounty_id: "", // 州/县id
                city_name: "", // 城市名或州县名
                address: "", // 详细地址
                zip_code: "", // 邮政编码
                is_default: 1
            },
            nationList: [],// 所有国家数据列表
            stateCountyList: [],
            addressValue: "",
            flag: false, //防重复标识
            defaultRegions: [],
            rules: {
                name: [{ required: true, message: "Please enter the consignee name", trigger: "blur" }],
                // email: [{ required: true, message: "Please input the email address", trigger: "blur" }],
                mobile: [{ required: true, message: "Please enter the phone number", trigger: "blur" }],
                city_name: [{ required: true, message: "please enter city", trigger: "blur" }],
                address: [{ required: true, message: "Please enter detailed address", trigger: "blur" }],
                country_id: [{ required: true, validator: fullAddress, trigger: "blur" }],
                stateCounty_id: [{ required: true, validator: fullAddress, trigger: "blur" }],
                // full_address: [{ required: true, validator: fullAddress, trigger: "blur" }],
                zip_code: [{ required: true, message: "Please enter zip code", trigger: "blur" }]
            },
            province: [],
            city: [],
            district: [],
            pickerValueArray: [],
            multiIndex: [0, 0, 0],
            isInitMultiArray: false,
            // 是否加载完默认地区
            isLoadDefaultAreas: false,
            loading: true,
            yes: true,
            Token: ''
        }
    },
    async created() {
        if (this.$route.query.id) {
            this.formData.id = this.$route.query.id
            await this.getAddressDetail();
        }
        // 获取所有的国家列表
        await this.getNation();
        this.Token = getToken();
    },
    mounted() {
        let self = this;
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    watch: {
        defaultRegions: {
            handler(arr, oldArr = []) {
                // 避免传的是字面量的时候重复触发
                if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
                this.handleDefaultRegions()
            },
            immediate: true
        }
    },
    computed: {
        pickedArr() {
            // 进行初始化
            if (this.isInitMultiArray) {
                return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
            }
            return [this.pickerValueArray[0], this.city, this.district]
        }
    },
    methods: {
        /**
         * 获取国家列表
         */
        getNation() {
            getCountries().then(res => {
                if (res.code >= 0) {
                    this.nationList = [];
                    for (const i in res.data) {
                        this.nationList.push(res.data[i]);
                    }
                    // console.log(this.nationList);
                    this.loading = false
                }
            })
        },
        /**
         * 获取州/县列表
         */
        getStateCounty(id) {
            getAreaByPid({
                pid: id
            }).then(res => {
                if (res.code >= 0) {
                    this.stateCountyList = [];
                    for (const i in res.data) {
                        this.stateCountyList.push(res.data[i]);
                    }
                    this.loading = false
                }
            })
        },
        /**
         * 改变国家
         */
        changeProvice(id) {
            this.getStateCounty(id);
        },
        /**
         * 改变市
         */
        changeCity(id) {
            this.getAreas(id, data => (this.district = data))
            let obj = {}
            obj = this.city.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.formData.district_id = ""
            this.formData.full_address = this.formData.full_address + "-" + obj.name
        },
        /**
         * 改变区
         */
        changeDistrict(id) {
            let obj = {}
            obj = this.district.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.formData.full_address = this.formData.full_address + "-" + obj.name
        },
        /**
         * 获取地址信息
         */
        getAddressDetail() {
            addressInfo({
                id: this.formData.id
            })
                .then(res => {
                    let data = res.data
                    if (data != null) {
                        this.formData.email = data.email // 邮箱
                        this.formData.name = data.name // 姓名
                        this.formData.mobile = data.mobile // 电话
                        this.formData.areacode = data.phone_code_id // 区号
                        this.formData.zip_code = data.zip_code // 邮政编码
                        this.formData.address = data.address // 详细地址
                        this.formData.country_id = data.country_id //国家
                        this.changeProvice(this.formData.country_id);
                        this.formData.stateCounty_id = data.province_id; // 州/县/省
                        this.formData.city_name = data.city_name; // 城市

                    }
                })
                .catch(err => { })
        },
        // 异步获取地区
        getAreas(pid, callback) {
            getArea({
                pid: pid
            })
                .then(res => {
                    if (res.code == 0) {
                        var data = []
                        res.data.forEach((item, index) => {
                            data.push(item)
                        })
                        if (callback) callback(data)
                    }
                })
                .catch(err => { })
        },

        /**
         * 获取省市区列表
         */
        getDefaultAreas(pid, obj) {
            getArea({
                pid: pid
            })
                .then(res => {
                    if (res.code == 0) {
                        var data = []
                        var selected = undefined
                        res.data.forEach((item, index) => {
                            if (obj != undefined) {
                                if (obj.level == 0 && obj.province_id != undefined) {
                                    selected = obj.province_id
                                } else if (obj.level == 1 && obj.city_id != undefined) {
                                    selected = obj.city_id
                                } else if (obj.level == 2 && obj.district_id != undefined) {
                                    selected = obj.district_id
                                }
                            }

                            if (selected == undefined && index == 0) {
                                selected = item.id
                            }
                            data.push(item)
                        })

                        this.pickerValueArray[obj.level] = data
                        if (obj.level + 1 < 3) {
                            obj.level++
                            this.getDefaultAreas(selected, obj)
                        } else {
                            this.isInitMultiArray = true
                            this.isLoadDefaultAreas = true
                        }

                        this.province = this.pickerValueArray[0]
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },

        /**
         * 渲染默认值
         */
        handleDefaultRegions() {
            var time = setInterval(() => {
                if (!this.isLoadDefaultAreas) return
                this.isInitMultiArray = false

                for (let i = 0; i < this.defaultRegions.length; i++) {
                    for (let j = 0; j < this.pickerValueArray[i].length; j++) {
                        this.province = this.pickerValueArray[0]

                        // 匹配省
                        if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
                            // 设置选中省
                            this.$set(this.multiIndex, i, j)
                            // 查询市
                            this.getAreas(this.pickerValueArray[i][j].id, data => {
                                this.city = data

                                for (let k = 0; k < this.city.length; k++) {
                                    if (this.defaultRegions[1] == this.city[k].id) {
                                        // 设置选中市
                                        this.$set(this.multiIndex, 1, k)

                                        // 查询区县
                                        this.getAreas(this.city[k].id, data => {
                                            this.district = data

                                            // 设置选中区县
                                            for (let u = 0; u < this.district.length; u++) {
                                                if (this.defaultRegions[2] == this.district[u].id) {
                                                    this.$set(this.multiIndex, 2, u)
                                                    this.handleValueChange({
                                                        detail: {
                                                            value: [j, k, u]
                                                        }
                                                    })
                                                    break
                                                }
                                            }
                                        })

                                        break
                                    }
                                }
                            })
                        }
                    }
                }
                if (this.isLoadDefaultAreas) clearInterval(time)
            }, 100)
        },
        handleValueChange(e) {
            // 结构赋值
            let [index0, index1, index2] = e.detail.value
            let [arr0, arr1, arr2] = this.pickedArr
            let address = [arr0[index0], arr1[index1], arr2[index2]]

            this.formData.full_address = ""
            for (let i = 0; i < address.length; i++) {
                if (this.formData.full_address) {
                    this.formData.full_address = this.formData.full_address + "-" + address[i].name
                } else {
                    this.formData.full_address = this.formData.full_address + address[i].name
                }
            }
        },

        /**
         * 保存地址
         */
        saveAddress(formName) {
            // console.log(this.formData);
            // return
            this.$refs[formName].validate(valid => {
                if (valid) {
                    var data = {
                        id: this.formData.id,
                        email: this.formData.email, // 邮箱
                        name: this.formData.name, // 姓名
                        mobile: this.formData.mobile, // 电话
                        areacode: this.formData.areacode, // 区号
                        // telephone: this.formData.telephone,
                        country_id: this.formData.country_id, // 国家
                        province_id: this.formData.stateCounty_id, // 州/县
                        city_name: this.formData.city_name, // 城市
                        zip_code: this.formData.zip_code, // 邮政编码
                        address: this.formData.address, // 详细地址
                        is_default: this.formData.is_default
                    }
                    data.url = "add"
                    if (this.formData.id) {
                        data.url = "edit"
                        data.id = this.formData.id
                    }
                    if (this.flag) return
                    this.flag = true

                    saveAddress(data)
                        .then(res => {
                            if (res.code == 0) {
                                this.$router.push({ path: "/member/delivery_address" })
                            } else {
                                this.flag = false
                                this.$message({ message: res.message, type: "warning" })
                            }
                        })
                        .catch(err => {
                            this.flag = false
                            this.$message.error(err.message)
                        })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
    box-shadow: unset;
}

.el-card {
    border: 0;
}

.ns-len-input {
    width: 350px;
}

.el-select {
    margin-right: 10px;
}
</style>
